:root {
    // Neutrals
    --black: rgba(0, 0, 0, 1);
    --black-5pct: rgba(0, 0, 0, 0.05);
    --black-10pct: rgba(0, 0, 0, 0.1);
    --black-20pct: rgba(0, 0, 0, 0.2);
    --black-30pct: rgba(0, 0, 0, 0.3);
    --black-40pct: rgba(0, 0, 0, 0.4);
    --black-50pct: rgba(0, 0, 0, 0.5);
    --black-60pct: rgba(0, 0, 0, 0.6);
    --black-70pct: rgba(0, 0, 0, 0.7);
    --black-80pct: rgba(0, 0, 0, 0.8);
    --black-90pct: rgba(0, 0, 0, 0.9);

    --gray-100: #f8f8f8;
    --gray-200: #d8d8d8;
    --gray-400: #a9a9a9;
    --gray-600: #57585A;
    --gray-700: #282828;

    --white: rgba(255, 255, 255, 1);
    --white-5pct: rgba(255, 255, 255, 0.05);
    --white-10pct: rgba(255, 255, 255, 0.1);
    --white-20pct: rgba(255, 255, 255, 0.2);
    --white-30pct: rgba(255, 255, 255, 0.3);
    --white-40pct: rgba(255, 255, 255, 0.4);
    --white-50pct: rgba(255, 255, 255, 0.5);
    --white-60pct: rgba(255, 255, 255, 0.6);
    --white-70pct: rgba(255, 255, 255, 0.7);
    --white-80pct: rgba(255, 255, 255, 0.8);
    --white-90pct: rgba(255, 255, 255, 0.9);

    // Theme
    --blue-100: rgba(192, 225, 242, 1);
    --blue-200: rgba(110, 187, 219, 1);
    --blue-600: rgba(0, 138, 189, 1);
    
    --orange-400: rgba(241, 96, 34, 1);
    --green-400: rgba(3, 147, 52, 1);
    --yellow-400: rgba(249, 190, 22, 1);
    
    --purple-400: rgba(112, 59, 130, 1);
    --purple-700: rgba(49, 40, 78, 1);
    
    --purple-grad-light: rgba(132, 68, 149, 1);
    --purple-grad-dark: rgba(44, 18, 65, 1);

    // Gradients
    --grad-black-vert: linear-gradient(0deg, var(--black) 0%, transparent 100%);
    --grad-blue-vert: linear-gradient(180deg, var(--blue-100) 20%, var(--blue-200) 80%);
    --grad-purple-vert--light-dark: linear-gradient(0deg, var(--purple-700) 10%, var(--purple-400) 100%);
    --grad-purple-vert--dark-light: linear-gradient(180deg, var(--purple-700) 0%, var(--purple-400) 90%);
    --grad-purple-card: linear-gradient(0deg, var(--purple-grad-dark) 10%, var(--purple-grad-light) 80%);
    --grad-purple-diag: linear-gradient(135deg, var(--purple-400) 5%, var(--purple-700) 70%);
    --grad-purple-transparent: linear-gradient(0deg, var(--purple-400) 0, transparent 40%);


    // Fonts, weights, sizes
    --font-sans: 'Raleway', sans-serif;
    --font-big-shoulders: 'Big Shoulders Text', sans-serif;
    --fontweight-normal: 400;
    --fontweight-medium: 500;
    --fontweight-bold: 700;
    --fontsize-h1: clamp(2.5rem, 6vw, 3.5rem);
    --fontsize-h2: clamp(2rem, 4.5vw, 2.5rem);
    --fontsize-h3: clamp(1.75rem, 4vw, 2rem);
    --fontsize-h4: clamp(1.75rem, 4vw, 2rem);
    --fontsize-h5: clamp(1.375rem, 3vw, 1.625rem);
    --fontsize-h6: clamp(1.375rem, 3vw, 1.625rem);
    --fontsize-p-xl: clamp(1.25rem, 1.75vw, 1.5rem);
    --fontsize-p-lg: clamp(1.125rem, 1.5vw, 1.25rem);
    --fontsize-p: rem(18px);

    // Typography, links
    --text-color: var(--purple-700);
    --link-color: #026ebb;
    --link-color-hover: #2ca0ce;
    --link-color-visited: var(--utility-purple);

    // Spacing
    --block-spacing-sm: 4rem;
    --block-spacing-md: 6rem;
    --block-spacing-lg: 8rem;
    --block-spacing-xl: 10rem;
    --block-spacing-sm-neg: -4rem;
    --block-spacing-md-neg: -6rem;
    --block-spacing-lg-neg: -8rem;
    --block-spacing-xl-neg: -10rem;

    // Utility
    --utlity-red: #a50034;
    --utility-purple: #402885;

    // Misc
    --border-radius: 0.375rem;
}

// Breakpoints
$bp-xs: 30em;  // 480px
$bp-sm: 40em;  // 640px
$bp-md: 50em;  // 800px
$bp-lg: 60em;  // 960px
$bp-xl: 80em;  // 1280px
$bp-xxl: 90em; // 1440px
