/*======================================
    Buttons
======================================*/

%btn {
    --btn-bg-color: var(--purple-400);
    --btn-bg-color-hover: var(--purple-700);
    --btn-border-color: var(--purple-400);
    --btn-border-color-hover: var(--purple-700);
    --btn-border-radius: var(--border-radius);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--white);
    --btn-focus-outline-color: var(--yellow-400);

    background-color: var(--btn-bg-color);
    border: 1px solid var(--btn-border-color);
    border-radius: var(--btn-border-radius);
    color: var(--btn-text-color);
    cursor: pointer;
    display: inline-block;
    font-size: rem(18px);
    font-weight: var(--fontweight-bold);
    line-height: 1.2;
    margin: 0 1em 1em 0;
    padding: 0.625em 1.5em;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color 250ms ease, border-color 250ms ease, color 250ms ease;
    white-space: nowrap;

    &:hover,
    &:focus {
        background-color: var(--btn-bg-color-hover);
        border-color: var(--btn-border-color-hover);
        color: var(--btn-text-color-hover);
    }
    
    &:focus {
        outline: 2px solid var(--btn-focus-outline-color);
        outline-offset: 2px;
    }
}

%btn-white {
    --btn-bg-color: var(--white);
    --btn-bg-color-hover: var(--yellow-400);
    --btn-border-color: var(--white);
    --btn-border-color-hover: var(--yellow-400);
    --btn-text-color: var(--purple-700);
    --btn-text-color-hover: var(--purple-700);
    --btn-focus-outline-color: var(--white);
}

.btn {
    @extend %btn;

    &-white {
        @extend %btn-white;
    }

    &-search {
        background-color: var(--btn-border-color);
        background-image: url('../images/ui/search-white.svg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 1rem;
        border: 0;
        min-width: 3rem;
        overflow: hidden;
        text-indent: -9999px;
    }

    .centered & {
        margin: 0 0.5rem 0.5rem;
    }

    &[disable],
    &.slick-disabled {
        --btn-bg-color: var(--gray-400);
        --btn-border-color: var(--gray-600);
        --btn-text-color: var(--gray-600);
        --btn-bg-color-hover: var(--gray-400);
        --btn-border-color-hover: var(--gray-400);
        --btn-text-color-hover: var(--gray-600);

        cursor: default;
        pointer-events: none;
        touch-action: none;
    }
}
