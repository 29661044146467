/* stylelint-disable */
@import 'https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap';
@import 'global/variables';
@import 'global/buttons';

body {
    //background-color: var(--gray-200);
    background-image: var(--grad-purple-diag);
    font-family: var(--font-sans);
    color: var(--black);
    font-weight: var(--fontweight-normal);
}

.login {
    position: relative;
    z-index: 2;
    
    &::after {
        background-image: url('../images/deco/hexagons-3-white-40pct.svg');
        background-position: left top;
        background-repeat: no-repeat;
        background-size: 350px auto;
        bottom: 0;
        content: '';
        display: block;
        height: 280px;
        position: absolute;
        right: 0;
        width: 280px;
        z-index: 1;
    }

    h1 a,
    .wp-login-logo a {
        background-color: transparent;
        background-image: url('../images/logo/changethegame.png');
        background-position: center center;
        background-size: contain;
        border: none;
        height: 100px;
        padding: 24px;
        width: 270px;
    }

    #loginform {
        //box-shadow: 0 0 2rem var(--black);

        input {
            &[type="text"],
            &[type="password"] {
                box-shadow: none !important;

                &:focus {
                    border: 1px solid var(--blue-600);
                    box-shadow: none !important;
                }
            }
        }
    }

    form {
        border-radius: 0.25rem;
        position: relative;

        .button-primary {
            @extend %btn;
            margin: 0;
        }
    }

    #backtoblog,
    #nav {
        a {
            color: var(--white);

            &:hover,
            &:focus {
                color: var(--blue-200);
            }
        }
    }

    #login_error,
    .message,
    .success {
        border-color: var(--utlity-red);
    }
}
